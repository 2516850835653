import React from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Card from "../../components/containers/card"
import Button from "../../components/interactive/button"

function BlogPostCard({ image, title, author, category, date, link }) {
  const img = getImage(image.node.localFile)

  return (
    <Card padding="none" className="card--blog-post">
      <GatsbyImage
        className="blog__listing__image"
        image={img}
        alt={image.node.altText}
      />
      <div
        className="card--blog-post__bg"
        style={{ background: category.color }}
      />
      <div className="card--blog-post__content">
        <p className="h4 blog__featured-post__author-text">
          {category.name} <span className="mr-xxs ml-xxs">|</span>{" "}
          {author.node.firstName} {author.node.lastName}{" "}
          <span className="mr-xxs ml-xxs">|</span>
          {"  "}
          {date}
        </p>
        <p className="h4">{title}</p>
        <Button styletype="blank" href={link}>
          View Article
        </Button>
      </div>
    </Card>
  )
}

export default BlogPostCard
