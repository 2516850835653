import React from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { Content } from "../grid"
import BlogCategory from "../accents/BlogCategory"
import Link from "../content/link"

function AdListing({ item }) {
  const imgFile = getImage(item.featuredImage.localFile)

  return (
    <Link to={item.link}>
      <Content
        gap="md"
        paddingLeft="none"
        paddingRight="none"
        className="blog__listing"
      >
        <div className="blog__listing__header accent accent--relative">
          <div className="blog__listing__cta">
            <p className="text--strong">{item.title}</p>
          </div>
          <GatsbyImage
            className="blog__listing__image"
            image={imgFile}
            alt={item.featuredImage.alternateText}
          />
        </div>

        <div className="blog__listing__seperator accent accent--relative">
          <div className="blog__listing__seperator__accent">
            <div className="accent__bookmark__square accent__bookmark__square--listing" />
          </div>
        </div>
        <BlogCategory color="core" name="Lunio" />
        <p className="blog__listing__title h3">{item.title}</p>
        <p className="h4 blog__featured-post__author-text">{item.content}</p>
      </Content>
    </Link>
  )
}

export default AdListing
