import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

function HeadingAccent({
  top, color, inverted, endless,
}) {
  const containerClasses = classNames(
    'accent accent__bookmark',
    inverted && 'accent__bookmark--inverted',
    color && `accent--${color}`,
  );

  const accentClasses = classNames(
    'accent__bookmark__left',
    inverted && 'accent__bookmark__left--inverted',
  );

  const squareClasses = classNames(
    'accent__bookmark__square',
    inverted && 'accent__bookmark__square--inverted',
  );

  const topDistance = { top: `${top}px` };

  return (
    <div style={topDistance} className={containerClasses}>
      <div className={accentClasses}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="accent__bookmark__rune"
          viewBox="0 0 180 181.2"
        >
          <path d="M165.1 76.6l-24.3-24.3L117 75.8l-11.7-11.1 25-25L90.7 0 50.9 39.8l25.2 24.8-11.4 11.3-25-25L0 90.5l39.8 39.8 24.7-24.7 11.4 10.8-25.1 25.1 39.7 39.7 39.8-39.8-26.3-25.9 11-10.3 25.5 25.5 27-27L180 91l-14.9-14.4zm-50.2 1.2l-4.8 4.7-42.8-42.7L90 17.1l22.8 22.8-16.2 16.2-3.4-3.4 12.3-12.6-15-15L76 39.7l38.9 38.1zM91.2 50.7l-11-11 10.4-10.4 10.8 10.8-10.2 10.6zM55 39.8L90.7 4.2l35.5 35.5-23 23-4.5-4.5L117 39.9 90 13 63.1 39.8 108 84.7l-3.7 3.7L55 39.8zm47.2 50.6L90.4 102 79.2 91l11.7-11.7 11.3 11.1zM78 66.8l3.7 3.7L39.2 113 16.1 90l23.7-23.7 17.3 17.3-4.3 4.3-12.7-12.2-15 15 14.5 14.5L78 66.8zM50.7 90l-11 11-10.4-10.4 10.8-10.8L50.7 90zm-10.9 36.2L4.2 90.5 39.7 55l23 23-3.5 3.5-19.4-19.3L12 90l27.2 27.2 44.6-44.6 4.7 4.7-48.7 48.9zm27.1-23l4.2-4.3.1.1 42.4 42.4-22.9 22.9-22.9-22.9 16.6-16.6 3.7 3.7-12.3 12.6 15 15 14.5-14.5-38.4-38.4zM90 130.5l11 11-10.4 10.4L79.8 141 90 130.5zm36.2 10.9L90.5 177 55 141.5l23-23 4.2 4.2-18.7 18.7 27 27 27.1-27.1-44.4-44.5 4.2-4.2.1.1 48.7 48.7zm-11.7-39.9l-12.6 11.9-3.8-3.8 42.4-42.4 22.9 22.9-23.1 23.1-16.6-16.6 5-5 11.8 11.8 13.9-13.9-13.9-13.9L128 88l-1.6 1.6-11.9 11.9zm16.1-12l9.7-9.7 9.7 9.7-9.7 9.7-9.7-9.7zm34.8 12.1l-24.9 24.9-23.4-23.4 4.4-4.4 18.7 18.7 25.2-25.2 2.1-2.1L140.4 63 96 107.5l-3.5-3.4 48.3-47.6L163 78.7 175.8 91l-10.4 10.6z" />
        </svg>
        <div className="accent__bookmark__bar" />
      </div>
      <div className="accent__bookmark__bg">
        {!endless && <div className={squareClasses} />}
      </div>
    </div>
  );
}

HeadingAccent.defaultProps = {
  top: 0,
  color: null,
  inverted: false,
  endless: false,
};

HeadingAccent.propTypes = {
  top: PropTypes.number,
  color: PropTypes.oneOf([
    'nebula',
    'pebble',
    'ragnarok',
    'terra',
    'coral',
    'arcade',
    'qwerty',
    'midgard',
    'core',
  ]),
  inverted: PropTypes.bool,
  endless: PropTypes.bool,
};

export default HeadingAccent;
