import React from "react"
import { Link } from "gatsby"

function Pagination({ numPages, currentPage, rootslug }) {
  let prevPage
  let nextPage
  prevPage =
    currentPage - 1 === 1 ? `/${rootslug}/` : `/${rootslug}/${currentPage - 1}/`
  nextPage =
    currentPage + 1 === numPages
      ? `/${rootslug}/${numPages}/`
      : `/${rootslug}/${currentPage + 1}/`

  const isFirst = currentPage === 1
  const isLast = currentPage === numPages

  if (numPages === 1) {
    return null
  }

  const showPrevSkip = !isFirst && currentPage !== 2
  const showNextSkip = !isLast && currentPage !== numPages - 1

  return (
    <div className="pagination__container">
      {showPrevSkip && (
        <Link to={`/${rootslug}/`} className="pagination">
          1...
        </Link>
      )}
      {!isFirst && (
        <Link to={prevPage} className="pagination ml-sm">
          {currentPage - 1}
        </Link>
      )}
      <p className="pagination pagination--current">{currentPage}</p>
      {!isLast && (
        <Link to={nextPage} className="pagination mr-sm">
          {currentPage + 1}
        </Link>
      )}
      {showNextSkip && (
        <Link to={`/${rootslug}/${numPages}/`} className="pagination">
          ...
          {numPages}
        </Link>
      )}
    </div>
  )
}

export default Pagination
