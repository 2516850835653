import React from 'react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { Content } from '../grid';
import BlogCategory from '../accents/BlogCategory';
import Link from '../content/link';

function Listing({ item }) {
  const imgFile = getImage(item.featuredImage.node.localFile);

  return (
    <Link to={item.realSlug}>
      <Content
        gap="md"
        paddingLeft="none"
        paddingRight="none"
        className="blog__listing"
      >
        <div
          className={`blog__listing__header accent--${item.categories.nodes[0].data.color}`}
        >
          <div className="blog__listing__cta">
            <p className="text--strong">View Blog Post</p>
          </div>
          <GatsbyImage
            className="blog__listing__image"
            image={imgFile}
            alt={item.featuredImage.node.altText}
          />
        </div>
        <div
          className={`blog__listing__seperator accent--${item.categories.nodes[0].data.color}`}
        >
          <div className="blog__listing__seperator__accent">
            <div className="accent__bookmark__square accent__bookmark__square--listing" />
          </div>
        </div>
        <BlogCategory
          color={item.categories.nodes[0].data.color}
          name={item.categories.nodes[0].name}
        />
        <p className="blog__listing__title h3">{item.title}</p>
        <p className="h4 blog__featured-post__author-text">
          {item.author.node.firstName}
          {' '}
          {item.author.node.lastName}
          {' '}
          <span className="mr-xxs ml-xxs">|</span>
          {'  '}
          {item.date}
        </p>
      </Content>
    </Link>
  );
}

export default Listing;
