import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { createUseStyles } from 'react-jss';
import ConditionalWrapper from '../../../utils/ConditionalWrapper';
import Link from '../../content/link';

const useStyles = createUseStyles((theme) => ({
  category: {
    color: theme.colors.lights.white,
    padding: '3px 12px 5px',
    borderRadius: 8,
  },
  color: (props) => ({
    backgroundColor: ['core', 'coreLight', 'coreDark'].includes(props.color) ? theme.colors.core[props.color] : theme.colors.accents[props.color],
  }),
  department: {
    margin: '8px auto 0',
  },
}));

function BlogCategory(props) {
  const {
    name, color, department, link,
  } = props;
  const cx = useStyles({ ...props });

  const classes = classNames(
    'category',
    { [cx.color]: color },
    { [cx.department]: department },
  );

  return (
    <div className="flex category__container">
      <ConditionalWrapper
        condition={link}
        wrapper={(children) => <Link to={link}>{children}</Link>}
      >
        <div className={classes}>
          <p className="h4">{name}</p>
        </div>
      </ConditionalWrapper>
    </div>
  );
}

BlogCategory.defaultProps = {
  color: 'terra',
  link: false,
  department: false,
};

BlogCategory.propTypes = {
  name: PropTypes.string.isRequired,
  color: PropTypes.oneOf([
    'nebula',
    'pebble',
    'ragnarok',
    'terra',
    'coral',
    'arcade',
    'qwerty',
    'midgard',
    'core',
    'core-light',
  ]),
  department: PropTypes.bool,
  link: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
};

export default BlogCategory;
