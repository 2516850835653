import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import Section from "../containers/section"
import SectionContent from "../containers/section-content"
import Heading from "../content/heading"
import { Columns, Rows } from "../grid"
import CtaGroup from "../containers/cta-group"
import Button from "../interactive/button"
import Content from "../grid/content"

function TryUsBar() {
  const {
    strapiCompTryUsBar: { heading, buttons },
  } = useStaticQuery(graphql`
    query TryUsBar {
      strapiCompTryUsBar {
        heading {
          preHeading
          primaryHeading
        }
        buttons {
          color
          link
          text
          type
        }
      }
    }
  `)

  const imageStyles = {}

  return (
    <Section>
      <SectionContent paddingBot="xl">
        <div className="text-gradient__purple-blue relative overflow--hidden border--rounded">
          <Rows>
            <Columns count="2,1">
              <Content className="py-lg lap-py-xxl pl-lg pr-lg lap-pr-none">
                <Heading
                  className="text--color-white"
                  preheading={heading.preheading}
                  alignment="left"
                >
                  {heading.primaryHeading}
                </Heading>
                <CtaGroup alignment="left">
                  {buttons.map(({ color, link, text, type }, index) => (
                    <Button
                      key={index}
                      color={color}
                      href={link}
                      styletype={type}
                    >
                      {text}
                    </Button>
                  ))}
                </CtaGroup>
              </Content>
              <Content className="hidden--tab-down">
                <StaticImage
                  src="../../images/common/red-w-map.png"
                  alt="Red with map explorer"
                  className="footer-images__cta"
                  style={imageStyles}
                  quality={100}
                />
              </Content>
            </Columns>
          </Rows>
        </div>
      </SectionContent>
    </Section>
  )
}

export default TryUsBar
