import React from "react"
import { graphql } from "gatsby"
import { GatsbyImage, getImage, StaticImage } from "gatsby-plugin-image"
import Section from "../../components/containers/section"
import SectionContent from "../../components/containers/section-content"
import Copy from "../../components/content/copy"
import Heading from "../../components/content/heading"
import { Columns, Content, Rows } from "../../components/grid"
import Main from "../../components/layout/main"
import SubSection from "../../components/containers/section-sub"
import CtaGroup from "../../components/containers/cta-group"
import Button from "../../components/interactive/button"
import HeadingAccent from "../../components/accents/heading-accent"
import SectionBackground from "../../components/containers/section-background"
import Listing from "../../components/blog/listing"
import AdListing from "../../components/blog/ad-listing"
import TryUsBar from "../../components/sections/try-us-bar"
import PostsCarousel from "../../components-smart/carousel/posts-carousel"
import Pagination from "../../components/interactive/pagination"
import Seo from "../../components/seo/seo"
import Link from "@src/components/content/link"

function BlogPage({ pageContext, data }) {
  const { blogPage } = data.wpPage
  const featuredArticle = pageContext.isSubPage
    ? pageContext.featuredPost
    : blogPage.featuredArticle[0]

  const featuredBlogImage = getImage(
    featuredArticle.featuredImage.node.localFile,
  )

  const seoData = {
    title: pageContext.isSubPage
      ? pageContext.seo.title
      : data.wpPage.seo.title,
    description: pageContext.isSubPage
      ? pageContext.seo.metaDesc
      : data.wpPage.seo.metaDesc,
    url:
      pageContext.currentPage > 1
        ? `/${pageContext.rootslug}/${pageContext.currentPage}`
        : `/${pageContext.rootslug}/`,
  }

  return (
    <Main>
      <Seo customData={seoData} />
      <header>
        <Section wave="boat" theme="dark">
          <SectionBackground>
            <div className="blog__hero blog__hero__image">
              <StaticImage src="../../images/blog/blog-library.png" />
            </div>
            <div className="blog__hero blog__hero__overlay" />
          </SectionBackground>
          <SectionContent hero paddingTop="lg" paddingBot="xl">
            <Rows gap="none" className="mb-md mt-md">
              <Columns count="1">
                <Content>
                  <Heading alignment="left" level={1}>
                    {pageContext.isSubPage
                      ? pageContext.category
                      : blogPage.pageTitle}
                  </Heading>
                  <Copy className="blog__hero__copy">
                    <p>
                      {pageContext.isSubPage
                        ? pageContext.description
                        : blogPage.pageSubtext}
                    </p>
                  </Copy>
                  <Link
                    className="blog__featured-post--link mt-xs"
                    to={featuredArticle.realSlug}
                  >
                    <div className="relative blog__featured-post--top">
                      <div className="blog-post__author__tag blog-post__author__tag--featured">
                        <p className="text--strong text--small px-xxxs flex flex--align-center">
                          💜 Featured Post
                        </p>
                      </div>
                      <div className="blog-post__author__profile">
                        <GatsbyImage
                          image={featuredBlogImage}
                          alt={featuredArticle.featuredImage.altText}
                          className="blog__featured-post__cover"
                        />
                        <div className="px-xs pt-xs pb-md pr-xs tab-pb-xs tab-pl-none">
                          <p className="h4 mt-xxs">{featuredArticle.title}</p>
                          <p className="h4 blog__featured-post__author-text">
                            {featuredArticle.author.node.firstName}{" "}
                            {featuredArticle.author.node.lastName} |{" "}
                            {featuredArticle.date}
                          </p>
                        </div>
                      </div>
                    </div>
                  </Link>
                </Content>
              </Columns>
            </Rows>
          </SectionContent>
        </Section>
      </header>

      <Section>
        <SectionContent paddingTop="sm" paddingBot="xs">
          <CtaGroup>
            <Button
              href="/blog/"
              size="sm"
              color={pageContext.isSubPage ? "light" : "secondary"}
            >
              All
            </Button>
            {data.allWpCategory.nodes.map(({ name, slug, data }, index) => {
              const isCurrentCategory =
                pageContext.isSubPage && pageContext.rootslug === `blog/${slug}`
              return (
                <Button
                  key={index}
                  color={isCurrentCategory ? data.color : "light"}
                  href={`/blog/${slug}/`}
                  size="sm"
                >
                  {name}
                </Button>
              )
            })}
          </CtaGroup>
        </SectionContent>
      </Section>

      <Section>
        <SectionContent paddingTop="none">
          <Rows gap="xl">
            <div className="blog__articles">
              {data.allWpPost.nodes.map((item, index) => (
                <Listing key={index} item={item} />
              ))}
              <AdListing item={blogPage.cta} />
            </div>
            <Pagination
              rootslug={pageContext.rootslug}
              numPages={pageContext.numPages}
              currentPage={pageContext.currentPage}
            />
          </Rows>
        </SectionContent>
      </Section>

      <Section>
        <SectionContent paddingTop="sm" paddingBot="sm">
          <Rows>
            <Columns count="1">
              <Content paddingLeft="sm">
                <Heading preheading={blogPage.featuredText.pretitle}>
                  {blogPage.featuredText.mainTitle}
                </Heading>
              </Content>
              <PostsCarousel cardsData={blogPage.bottomFeatured} />
            </Columns>
          </Rows>
        </SectionContent>
      </Section>

      <TryUsBar />
    </Main>
  )
}

export const query = graphql`
  query BlogPageQuery($categoryId: [String]!, $skip: Int!, $limit: Int!) {
    allWpCategory(filter: { name: { ne: "Uncategorized" } }) {
      nodes {
        name
        slug
        data {
          color
        }
      }
    }
    allWpPost(
      sort: { fields: date, order: DESC }
      filter: {
        categories: { nodes: { elemMatch: { id: { in: $categoryId } } } }
      }
      limit: $limit
      skip: $skip
    ) {
      nodes {
        date(formatString: "MMMM Do, YYYY")
        author {
          node {
            firstName
            lastName
          }
        }
        realSlug
        categories {
          nodes {
            name
            data {
              color
            }
          }
        }
        excerpt
        title
        featuredImage {
          node {
            altText
            localFile {
              publicURL
              childImageSharp {
                gatsbyImageData(width: 915, quality: 90, layout: CONSTRAINED)
              }
            }
          }
        }
      }
    }
    wpPage(slug: { eq: "blog" }) {
      seo {
        title
        metaDesc
      }
      blogPage {
        pageTitle
        pageSubtext
        cta {
          link
          title
          content
          featuredImage {
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
        featuredText {
          pretitle
          mainTitle
        }
        bottomFeatured {
          ... on WpPost {
            date(formatString: "MMMM Do, YYYY")
            author {
              node {
                firstName
                lastName
              }
            }
            title
            categories {
              nodes {
                name
                data {
                  color
                }
              }
            }
            realSlug
            featuredImage {
              node {
                localFile {
                  childImageSharp {
                    gatsbyImageData
                  }
                }
                altText
              }
            }
          }
        }
        featuredArticle {
          ... on WpPost {
            date(formatString: "MMMM Do, YYYY")
            author {
              node {
                firstName
                lastName
              }
            }
            title
            categories {
              nodes {
                name
                data {
                  color
                }
              }
            }
            realSlug
            featuredImage {
              node {
                localFile {
                  childImageSharp {
                    gatsbyImageData
                  }
                }
                altText
              }
            }
          }
        }
      }
    }
  }
`

export default BlogPage
