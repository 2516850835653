import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

function SubSection({
  theme, className, children, ...attributes
}) {
  const classes = classNames('section__sub', `section--${theme}`, className);

  return (
    <div className={classes} {...attributes}>
      {children}
    </div>
  );
}

SubSection.defaultProps = {
  theme: 'white',
};

SubSection.propTypes = {
  theme: PropTypes.oneOf(['white', 'light', 'dark']),
  children: PropTypes.node,
};

export default SubSection;
