import React from "react"
import Carousel from "./default"
import BlogPostCard from "./post-card"

function PostsCarousel({ cardsData }) {
  const cards = cardsData.map(
    ({ title, date, author, categories, realSlug, featuredImage }, index) => (
      <BlogPostCard
        image={featuredImage}
        title={title}
        author={author}
        category={categories.nodes[0]}
        date={date}
        link={realSlug}
        key={index}
      />
    ),
  )

  return <Carousel centered slides={cards} />
}

export default PostsCarousel
